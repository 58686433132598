import axios, { AxiosPromise } from 'axios';
import qs from 'qs';

export default {
    search: (
        query?: { [key: string]: any },
    ): AxiosPromise => {
        return axios.get(`exact?${qs.stringify(query)}`);
    },
    update: (productId: number): AxiosPromise => {
        return axios.post(`products/${productId}/update-exact`);
    }
}
