import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from '.';
import product from './product';

type OrientationType = 'landscape' | 'portrait';

export interface ApiImage {
    id: number,
    extension: string,
    height: number,
    name: string,
    orientation: OrientationType,
    src: string,
    width: number,
    productsCount?: number,
}

export default {
    delete: (imageId: number | string): AxiosPromise => {
        return axios.delete(`images/${imageId}`);
    },
    get: (imageId: number): AxiosPromise<ApiImage> => {
        return axios.get(`images/${imageId}`);
    },
    list: (query?: { [key: string]: any }): AxiosPromise<ApiPaginatedResponse<ApiImage[]>> => {
        return axios.get(`images?${qs.stringify(query)}`);
    },
    sort: (productId: number, images: number[], type: string = 'products'): AxiosPromise => {
        return axios.post(`${type}/${productId}/sort-images`, { images });
    },
    rotate: (imageId: number): AxiosPromise => {
        return axios.post(`images/${imageId}/rotate`);
    },
    upload: (
        file: any,
        onUploadProgress?: (progressEvent: any) => void,
        id?: number,
        key: string = 'product_id',
    ): AxiosPromise => {
        const fd = new FormData();
        fd.append('file', file);
        if (id) fd.append(key.replace('promo-', ''), `${id}`);
        if (key) fd.append('key', `${key.replace('promo-', '')}`);

        if (key.includes('promo-')) {
            fd.append('ispromo', '1');
        }

        return axios.post('images', fd, {
            onUploadProgress,
        });
    }
}
