import React, { FC, useEffect, useState } from 'react';
import { request } from '../../api';
import { ApiProduct } from '../../api/product';
import { Segment, Modal, Button } from '../../ApuKit';
import SortableList, { reorder } from '../../components/SortableList';

interface SortModeProps {
    onCancel: () => void,
    onUpdate: (reordered?: ApiProduct[]) => void,
    specs: ApiProduct[],
}

const SortMode: FC<SortModeProps> = ({ onCancel, onUpdate, specs }): JSX.Element => {
    const [ sorted, setSorted ] = useState<ApiProduct[]>(specs);
    useEffect(() => setSorted(specs), [specs]);

    const onDragEnd = (result: any): void => {
        if (result.destination) {
            setSorted(reorder(sorted, result.source.index, result.destination.index));
        }
    }

    const handleSave = (): void => {
        request.post('products/bulk/update-position', {
            products: sorted.map((o, i) => o.id),
        });
        onUpdate(sorted);
    }

    return (<>
        <div style={{ marginBottom: '1rem' }}>
            <Button
                label="Opslaan"
                onClick={() => handleSave()}
                primary
            />
            <Button
                label="Annuleren"
                onClick={onCancel}
            />
        </div>
        <SortableList
            items={sorted}
            onUpdate={onDragEnd}
            renderListItem={(spec) => (
                <Segment padding="dense">
                    {spec.sku}
                </Segment>
            )}
        />
    </>);
}

export default SortMode;
