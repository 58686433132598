import { faSyncAlt, faTrash, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { FC } from 'react';
import api from '../../api';
import { ApiImage } from '../../api/image';
import { Confirm, Menu } from '../../ApuKit';
import styles from './styles.module.scss';

interface ImageThumbProps {
    confirm?: string,
    image: ApiImage,
    onDelete?: (image: ApiImage) => void;
    onRotate?: (image: ApiImage) => void;
}

const ImageThumb: FC<ImageThumbProps> = ({ confirm, image, onDelete, onRotate }): JSX.Element => {
    const handleDelete = (image: ApiImage) => {
        if (onDelete) {
            onDelete(image);
        }
    }

    const rotateImage = (image: ApiImage) => {
        api.rotateImage(image.id).then(() => {
            if (onRotate) {
                onRotate(image);
            }
        });
    }

    return (
        <div
            className={[
                styles.thumbnail,
            ].join(' ')}
        >
            <img src={image.src} alt={image.name} />
            <div className={styles.menu}>
                <Menu dropdown>
                    <Menu.Item
                        icon={faSyncAlt}
                        content="Draaien"
                        onClick={() => rotateImage(image)}
                    />
                    <Menu.Divider />
                    {onDelete && <>
                        <Confirm
                            content={confirm || 'Weet je zeker dat je deze afbeelding wilt verwijderen?'}
                            onConfirm={() => handleDelete(image)}
                            trigger={<Menu.Item
                                icon={faTrashAlt}
                                content="Verwijderen"
                            />}
                        />
                    </>}
                </Menu>
            </div>
        </div>
    );
}

export default ImageThumb;
