import React, { FC } from 'react';
import { find } from 'lodash';
import { Checkbox } from '../../ApuKit';
import { ApiProduct } from '../../api/product';
import styles from './styles.module.scss';

const mats: { [key: string]: string } = {
    P: 'STAAL',
    M: 'RVS',
    K: 'GIETIJZER',
    S: 'TITAAN / HITTEBESTENDIG',
    H: 'GEHARD STAAL',
    N: 'ALUMINIUM / NON-FERRO',
    G: 'GRAFIET',
};

interface ProductAttributesProps {
    attributeId: number,
    product: Partial<ApiProduct>,
    onUpdate: (product: Partial<ApiProduct>) => void,
}

const ProductAttributes: FC<ProductAttributesProps> = ({ attributeId, product, onUpdate }): JSX.Element => {
    const toggleAttribute = (checked: boolean, attribute: string): void => {
        let newAttr = product.attributes || [];
        
        if (checked) {
            newAttr.push({
                value: attribute,
                attributeId,
            });
        } else {
            newAttr = newAttr.filter(o => o.attributeId !== attributeId || o.value !== attribute);
        }

        onUpdate({
            ...product,
            attributes: newAttr,
        });
    }

    return (
        <div className={styles.attributes}>
            {Object.keys(mats).map((mat, i) => {
                const checked = find(product.attributes, {
                    attributeId,
                    value: mats[mat],
                }) !== undefined;
            
                return (
                    <div
                        className={styles.attribute}
                        key={`mat-${i}`}
                        onClick={() => toggleAttribute(!checked, mats[mat])}
                    >
                        <div className={styles[mat]}>
                            {mat}
                        </div>
                        <Checkbox
                            checked={checked}
                        />
                    </div>
                );
            })}
        </div>
    );
}

export default ProductAttributes;
