import { faImage } from '@fortawesome/pro-light-svg-icons';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import api from '../../api';
import { Form, Checkbox, PageHeader, Segment, Table, toast, DropdownOption } from '../../ApuKit';
const config = require('../../config.json');

export default () => {
    const [ results, setResults ] = useState<any>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ selection, setSelection ] = useState<any[]>([]);
    const [ next, setNext ] = useState<boolean>(false);
    const [ fetchNext, setFetchNext ] = useState<string>('');
    const [ query, setQuery ] = useState<string>('');
    const [ type, setType ] = useState<string>('code');

    const handleSearch = (nextItem?: string): void => {
        if (query === '') return;
        setIsLoading(true);

        api.searchExact({
            query,
            type,
            next: nextItem,
        }).then(({ data }) => {
            setIsLoading(false);
            setResults(nextItem ? [...(results || []), ...data.data] : data.data);
            setNext(data.next);
        }).catch(() => {
            window.location.href = `${config.baseUrl}/connect/exact`;
        });
    }

    useEffect(() => {
        if (fetchNext === '') return;
        handleSearch(fetchNext);
    }, [fetchNext]);

    useEffect(() => {
        handleSearch();
    }, [type, query]);

    const handleSelection = (productId: number, state: boolean): void => {
        let newSelection = [...selection];
        if (state) {
            newSelection.push(productId);
        } else {
            newSelection = newSelection.filter(o => o !== productId);
        }
        setSelection(newSelection);
    }

    const handleSelectAll = (state: boolean): void => {
        setSelection(state ? results : []);
    }

    const addProducts = ({ value }: { value: number }): void => {
        if (!value) return;
        api.bulk.addProductsFromExact(selection, value).then(() => {
            toast('Producten toegevoegd');
        });
    }

    const handleAutoComplete = (query: string, callback: (options: DropdownOption[]) => void) => {
        api.listCategories({ query, all: 1 }).then(({ data }) => {
            callback(data.map((o) => ({
                text: o.name,
                value: o.id,
            })));
        });
    }

    return (<>
        <PageHeader
            breadcrumb={{'exact': 'Exact Online'}}
            title="Zoeken in Exact Online"
        />

        {selection.length > 0 && <Form.Dropdown
            autoComplete={handleAutoComplete}
            clearable
            label="Voeg producten toe aan categorie"
            onChange={(e: any) => addProducts(e)}
            placeholder="Kies een categorie"
            value=""
        />}

        <div style={{ display: 'inline-block', marginBottom: '1rem' }}>
            <Form.Dropdown
                label="Zoeken op"
                options={[{
                    text: 'Code',
                    value: 'code',
                }, {
                    text: 'Productgroep',
                    value: 'group',
                }]}
                onChange={({ value }) => setType(value)}
                value={type}
            />
        </div>

        <Segment card isLoading={isLoading}>
            <Table.Actions onSearch={(q: string) => setQuery(q)} />
            {results && <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell collapsing>
                            <Checkbox
                                checked={results.length > 0 && selection.length === results.length}
                                onChange={(data: any) => handleSelectAll(data.checked)}
                            />
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing>
                            SKU
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Omschrijving
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing>
                            Groep
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            align="center"
                            collapsing
                            icon={faImage}
                        />
                    </Table.Row>
                </thead>
                <tbody>
                    {results.length > 0 ? results.map((result: any, index: number) => (
                        <Table.Row key={index}>
                            <Table.Cell collapsing>
                                <Checkbox
                                    checked={find(selection, { Code: result.Code }) ? true : false}
                                    onChange={(data: any) => handleSelection(result, data.checked)}
                                />
                            </Table.Cell>
                            <Table.Cell collapsing>
                                {result.Code}
                            </Table.Cell>
                            <Table.Cell>
                                {result.Description}
                            </Table.Cell>
                            <Table.Cell collapsing>
                                {result.ItemGroupDescription} ({result.ItemGroupCode})
                            </Table.Cell>
                            <Table.Cell collapsing>
                                {result.PictureUrl && result.PictureUrl !== 'https://start.exactonline.nl//docs/images/placeholder_item.png'
                                    ? <FontAwesomeIcon icon={faCheck} className="success" />
                                    : <FontAwesomeIcon icon={faTimes} className="error" />}
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>}
        </Segment>
        {next && <Table.Pagination
            meta={{
                current_page: 0,
                from: 0,
                last_page: 1,
                path: '',
                per_page: 60,
                to: 120,
                total: 9999,
            }}
            onChange={() => setFetchNext(results[results.length - 1].ID)}
            infinite
            isLoading={isLoading}
        />}
    </>);
}
