import axios from 'axios';
import config from '../config.json';
import auth from './auth';
import category from './category';
import exact from './exact';
import image from './image';
import importer from './import';
import product from './product';
import promotion from './promotion';
import promocategory from './promocategory';
import filter from './filter';

export const getToken = () => {
    if (localStorage.getItem('accessToken')) {
        return localStorage.getItem('accessToken');
    }
    
    return null;
}

axios.defaults.baseURL = config.baseUrl;
axios.defaults.headers.common = { 'X-Requested-With': 'XMLHttpRequest' };

axios.interceptors.request.use((config) => {
    const accessToken = getToken(); 
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
});

axios.interceptors.response.use((res: any) => res, (err: any) => {
    if (err.message.indexOf(401) !== -1) {
        localStorage.removeItem('accessToken');
    }
    return Promise.reject(err);
});

export interface ApiMeta {
    current_page: number,
    from: number,
    last_page: number,
    path: string,
    per_page: number,
    to: number,
    total: number,
}

export interface ApiPaginatedResponse<T> {
    data: T,
    meta: ApiMeta,
    links: {
        first: string,
        last: string,
        next?: string,
        prev?: string,
    },
}

export const request = axios;

const api = {
    bulk: {
        addProductsFromExact: product.addBulkExact,
        updateCategory: product.updateBulkCategory,
        deleteProduct: product.deleteBulk,
    },
    getProfile: auth.getProfile,
    login: auth.login,
    logout: auth.logout,
    deleteCategory: category.delete,
    getCategory: category.get,
    listCategories: category.list,
    putCategory: category.put,
    sortCategories: category.sort,
    updateCategoryPrices: category.updatePrices,

    deletePromotionCategory: promocategory.delete,
    getPromotionCategory: promocategory.get,
    listPromotionCategories: promocategory.list,
    putPromotionCategory: promocategory.put,
    sortPromotionCategories: promocategory.sort,
    updatePromotionCategoryPrices: promocategory.updatePrices,

    searchExact: exact.search,
    updateFromExact: exact.update,

    getFilter: filter.get,
    listFilters: filter.list,

    deleteImage: image.delete,
    getImage: image.get,
    listImages: image.list,
    sortImages: image.sort,
    rotateImage: image.rotate,
    uploadImage: image.upload,
    uploadImport: importer.upload,

    addProductSpecColumn: product.addSpecColumn,
    deleteProduct: product.delete,
    deleteProductSpecification: product.deleteSpecification,
    getProduct: product.get,
    listProducts: product.list,
    listAllProducts: product.listAll,
    saveSpecifications: product.saveSpecifications,
    sortProductSpecs: product.sortSpecs,
    putProduct: product.put,
    unlinkProductImage: product.unlinkImage,
    
    deletePromotion: promotion.delete,
    getPromotion: promotion.get,
    listPromotions: promotion.list,
    putPromotion: promotion.put,
    unlinkPromotionImage: promotion.unlinkImage,
};

export default api;
