import { faUpload } from '@fortawesome/pro-light-svg-icons';
import React, { FC, RefObject, useEffect, useState } from 'react';
import api from '../../api';
import { ApiCategory } from '../../api/category';
import { ApiFilter } from '../../api/filter';
import { Button, Checkbox, DropdownOption, Form, Modal, toast } from '../../ApuKit';

interface CategoryCreateModalProps {
    onClose: (update: boolean) => void,
    open: boolean,
    category: ApiCategory,
}

const CategoryEditModal: FC<CategoryCreateModalProps> = ({ category, onClose, open }) => {
    const fileInputRef: RefObject<HTMLInputElement>[] = [React.createRef(), React.createRef()];
    const [ editableCategory, setEditableCategory ] = useState<ApiCategory>(category);
    const [ filters, setFilters ] = useState<ApiFilter[]>([]);

    useEffect(() => {
        if (!open) return;
        api.listFilters().then(({ data }) => {
            setFilters(data);
        });
    }, [open]);
    
    useEffect(() => {
        setEditableCategory({
            ...category,
            filters: category.filters ? category.filters.map(o => o.id) : [],
        });
    }, [category]);

    const save = (): void => {
        api.putCategory(editableCategory).then(() => {
            onClose(true);
            toast('Cagegorie succesvol gewijzigd');
        });
    }

    const handleAutoComplete = (query: string, callback: (options: DropdownOption[]) => void) => {
        api.listCategories({ query, all: 1 }).then(({ data }) => {
            callback(data.map((o) => ({
                text: o.name,
                value: o.id,
            })));
        });
    }

    const handleInput = ({ name, value }: { [key: string]: any }) => {
        setEditableCategory({
            ...editableCategory,
            [name]: value,
        });
    }

    const uploadFile = (e: any, type: string): void => {
        api.uploadImage(e.target.files[0], undefined, editableCategory.id as number, type).then(({ data }) => {
            setEditableCategory({
                ...editableCategory,
                [type]: data,
            });
            toast('Afbeelding toegevoegd');
        });
    }

    return (
        <Modal
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        label="Annuleren"
                        link
                        onClick={() => onClose(false)}
                    />
                    <Button
                        label="Ok"
                        primary
                        onClick={() => save()}
                    />
                </div>
            )}
            header="Categorie wijzigen"
            onClose={() => onClose(false)}
            open={open}
            size="medium"
        >
            <Form.Dropdown
                autoComplete={handleAutoComplete}
                clearable
                defaultOption={editableCategory.parent?.name}
                label="Hoofdcategorie"
                name="parentId"
                onChange={handleInput}
                placeholder="Kies een hoofdcategorie"
                required
                value={editableCategory.parentId || ''}
            />
            <Form.Input
                label="Naam"
                required
                name="name"
                onChange={handleInput}
                value={editableCategory.name}
            />
            <Form.Input
                label="Zoektermen"
                required
                name="searchterms"
                onChange={handleInput}
                value={editableCategory.searchterms}
            />
            <Form.Dropdown
                clearable
                multiple
                name="filters"
                options={filters.map((f) => ({
                    text: f.attribute.name,
                    value: f.id,
                }))}
                onChange={handleInput}
                label="Filter(s)"
                value={editableCategory.filters || []}
            />
            <Form.Textarea
                label="Omschrijving"
                required
                name="description"
                onChange={handleInput}
                rows={1}
                value={editableCategory.description || ''}
            />
            <Checkbox
                label="Gecombineerde producten"
                checked={editableCategory.isCombined}
                onChange={({ checked }: any) => handleInput({ name: 'isCombined', value: checked })}
            />
            <div style={{ marginTop: '1rem', borderTop: 'solid 1px #cccccc;', paddingTop: '1rem', display: 'flex' }}>
                {['headerImage', 'thumbnail'].map((type: any, i: number) => {
                    const img = type === 'headerImage'
                        ? editableCategory.headerImage
                        : editableCategory.thumbnail;

                    return <div key={`type-${type}`} style={{ flex: 1 }}>
                        <div>
                            <label>{type === 'thumbnail' ? 'Thumbnail' : 'Header afbeelding'}</label>
                            <input
                                onChange={(e) => uploadFile(e, type)}
                                type="file"
                                accept="image/*"
                            />
                        </div>
                        {img && <img
                            alt=""
                            src={img.src}
                            style={{ maxHeight: 100, marginTop: '1rem' }}
                        />}
                    </div>
                })}
            </div>
        </Modal>
    );
}

export default CategoryEditModal;
