import React, { FC, useState } from 'react';
import api from '../../api';
import { Modal, Form, Button, toast } from '../../ApuKit';

interface AddSpecModalProps {
    onClose: (added?: boolean) => void,
    open: boolean,
    productId: number,
}

const AddSpecModal: FC<AddSpecModalProps> = ({ onClose, open, productId }): JSX.Element => {
    const [ errors, setErrors ] = useState<{ [key: string ]: any }>({});
    const [ data, setData ] = useState<{ [key: string ]: any }>({
        abbr: '',
        label: '',
    });

    const handleClose = (added?: boolean): void => {
        setErrors({});
        setData({});
        onClose(added);
    }

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setData({
            ...data,
            [name]: value,
        });
    }

    const addColumn = (): void => {
        setErrors(data.label === '' ? { label: true } : {});
        if (data.label === '') return;

        api.addProductSpecColumn(productId, data).then(() => {
            toast('Kolom toegevoegd');
            handleClose(true);
        });
    }

    return (
        <Modal
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        label="Annuleren"
                        link
                        onClick={() => handleClose()}
                    />
                    <Button
                        label="Ok"
                        primary
                        onClick={addColumn}
                    />
                </div>
            )}
            header="Nieuwe spec toevoegen"
            onClose={() => handleClose()}
            open={open}
            size="medium"
        >
            <Form.Group style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    <Form.Input
                        error={errors.label}
                        label="Label"
                        name="label"
                        required
                        onChange={handleInput}
                        value={data.label}
                    />
                </div>
                <div style={{ flex: 1, marginLeft: '1rem' }}>
                    <Form.Input
                        label="Afkorting"
                        name="abbr"
                        onChange={handleInput}
                        value={data.abbr}
                    />
                </div>
            </Form.Group>
        </Modal>
    );
}

export default AddSpecModal;
