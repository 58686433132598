import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiImage } from './image';
import { ApiFilter } from './filter';

export interface ApiCategory {
    id: number,
    childrenCount: number,
    name: string,
    description?: string,
    parent?: ApiCategory,
    parentId: number,
    position: number,
    productsCount: number,
    slug: string,
    isCombined: boolean,
    headerImage?: ApiImage,
    thumbnail?: ApiImage,
    filters?: any[],
    searchterms: string,
}

export default {
    delete: (categoryId: number): AxiosPromise => {
        return axios.delete(`categories/${categoryId}`);
    },
    get: (categoryId: number, qWith?: string): AxiosPromise<ApiCategory> => {
        return axios.get(`categories/${categoryId}?${qs.stringify({
            with: qWith,
        })}`);
    },
    list: (query?: { [key: string]: any }, qWith?: string): AxiosPromise<ApiCategory[]> => {
        return axios.get(`categories?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (category: Partial<ApiCategory>): AxiosPromise<ApiCategory> => {
        if (category.id) {
            return axios.put(`categories/${category.id}`, category);
        } else {
            return axios.post('categories', category);
        }
    },
    sort: (categories: ApiCategory[]): AxiosPromise => {
        return axios.post('categories/sort', { categories });
    },
    updatePrices: (categoryId: number): AxiosPromise => {
        return axios.post(`categories/${categoryId}/update-prices`);
    },
}
