import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faPencil, faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { faCheck, faCubes, faPlug, faTimes } from '@fortawesome/pro-solid-svg-icons';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { Button, Checkbox, Confirm, DropdownOption, Form, Menu, PageHeader, Segment, Table, toast } from '../../ApuKit';
import api, { ApiMeta } from '../../api';
import { ApiProduct } from '../../api/product';
import { number } from '../../lib/util';

const renderCategories = (product: ApiProduct): JSX.Element | undefined => {
    const cats: JSX.Element[] = [];
    if (!product.categories || product.categories.length <= 0) return;
    
    product.categories.map((category, index) => {
        if (index < (product.categories?.length || 0) - 1) {
            cats.push(<span key={`pc-${product.id}-${category.id}`}>
                <Link to={`/products?cat=${category.id}`}>
                    {category.name}
                </Link>
                {index < (product.categories?.length || 0) - 2 ? ' / ' : ''}
            </span>);
        }
        return category;
    });

    return (
        <small style={{ fontSize: 10, lineHeight: 1 }}>{cats}</small>
    );
}

interface ProductListViewProps {
    categoryId?: number,
}

const ProductListView: FC<ProductListViewProps> = ({ categoryId }): JSX.Element => {
    const [ category, setCategory ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState(true);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ products, setProducts ] = useState<ApiProduct[]>([]);
    const [ selection, setSelection ] = useState<number[]>([]);

    const fetch = (query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listProducts({
            query,
            page,
            categoryId,
        }, categoryId ? '' : 'category').then(({ data }) => {
            setMeta(data.meta);
            setProducts(page === 1 ? data.data : [...products, ...data.data]);
            setIsLoading(false);
        });
    }

    useEffect(() => fetch(), [categoryId]);

    const deleteProduct = (productId: number | string): void => {
        api.deleteProduct(productId).then(() => {
            fetch();
            toast('Product succesvol verwijderd');
        });
    }

    const handleAutoComplete = (query: string, callback: (options: DropdownOption[]) => void) => {
        api.listCategories({ query, all: 1 }, 'parent').then(({ data }) => {
            callback(data.map((o) => ({
                text: `${o.parent ? `${o.parent.name} | ` : ''}${o.name}`,
                value: o.id,
            })));
        });
    }

    const handleSelection = (productId: number, state: boolean): void => {
        let newSelection = [...selection];
        if (state) {
            newSelection.push(productId);
        } else {
            newSelection = newSelection.filter(o => o !== productId);
        }
        setSelection(newSelection);
    }

    const handleSelectAll = (state: boolean): void => {
        setSelection(state ? products.map(o => o.id as number) : []);
    }

    const handleBulkCategory = ({ value }: { value: number }): void => {
        if (!value) return;
        api.bulk.updateCategory(selection, value).then(() => {
            setSelection([]);
            toast('Categorie gewijzigd');
            fetch();
        });
    }

    const handleBulkDelete = (): void => {
        api.bulk.deleteProduct(selection).then(() => {
            setSelection([]);
            toast('Producten verwijderd');
            fetch();
        });
    }

    return (<>
        {categoryId ? (
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '3.125rem' }}>
                <Button
                    icon={faPlus}
                    href={`/products/create?cid=${categoryId}`}
                    label="Product toevoegen"
                    style={{ marginBottom: '1rem' }}
                />
            </div>
        ) : (
            <PageHeader title="Producten" breadcrumb={{'/products': 'Producten'}}>
                <Button
                    icon={faPlus}
                    href="/products/create"
                />
            </PageHeader>
        )}

        {selection.length > 0 && <>
            <Form.Dropdown
                autoComplete={handleAutoComplete}
                clearable
                label="Bulk categorie bijwerken"
                onChange={(e: any) => handleBulkCategory(e)}
                placeholder="Kies een categorie"
                value={category.value || ''}
            />
            <Confirm
                onConfirm={() => handleBulkDelete()}
                trigger={<Button label="Verwijderen" />}
            />
        </>}

        <Segment card isLoading={isLoading}>
            <Table.Actions
                onSearch={fetch}
                remember
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell collapsing>
                            <Checkbox
                                checked={selection.length === products.length}
                                onChange={(data: any) => handleSelectAll(data.checked)}
                            />
                        </Table.HeaderCell>
                        {!categoryId && <Table.HeaderCell collapsing>
                            Categorie
                        </Table.HeaderCell>}
                        <Table.HeaderCell>
                            SKU
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            align="right"
                            collapsing
                            icon={faCubes}
                        />
                        <Table.HeaderCell
                            align="right"
                            collapsing
                            icon={faPlug}
                        />
                        <Table.HeaderCell
                            align="center"
                            collapsing
                            icon={faImage}
                        />
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {products.length > 0 ? products.map((product, index) => (
                        <Table.Row key={`row-${product.id}`}>
                            <Table.Cell collapsing>
                                <Checkbox
                                    checked={selection.includes(product.id as number)}
                                    onChange={(data: any) => handleSelection(product.id as number, data.checked)}
                                />
                            </Table.Cell>
                            {!categoryId && <Table.Cell collapsing style={{ paddingRight: 32 }}>
                                <Link to={`/products?cat=${product.categoryId}`}>
                                    {product.category?.name}
                                </Link><br />
                                {renderCategories(product)}
                            </Table.Cell>}
                            <Table.Cell>
                                <Link to={`/products/${product.id}/edit`}>
                                    {product.sku}
                                </Link>
                            </Table.Cell>
                            <Table.Cell align="right" collapsing>
                                {number(product.childrenCount || 0)}
                            </Table.Cell>
                            <Table.Cell align="right" collapsing>
                                {product.exactChildrenCount === product.childrenCount ?
                                    <FontAwesomeIcon icon={faCheck} className="success" /> :
                                    number(product.exactChildrenCount || 0)}
                            </Table.Cell>
                            <Table.Cell align="center" collapsing>
                                {product.imagesCount
                                    ? <FontAwesomeIcon icon={faCheck} className="success" />
                                    :<FontAwesomeIcon icon={faTimes} className="error" />}
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Wijzigen"
                                        href={`/products/${product.id}/edit`}
                                    />
                                    <Menu.Divider />
                                    <Confirm
                                        content="Weet je zeker dat je dit product wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden"
                                        onConfirm={() => deleteProduct(product.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt}
                                            content="Verwijderen"
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
            infinite
            isLoading={isLoading}
        />}
    </>);
}

export default ProductListView;
