import { faFileExcel, faPlus, faRetweet, faTrashAlt, faUpload } from '@fortawesome/pro-light-svg-icons';
import { cloneDeep } from 'lodash';
import React, { FC, FormEvent, RefObject, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Button, Confirm, Checkbox, DropdownOption, Form, PageHeader, Segment, Table, toast, Grid } from '../../ApuKit';
import api, { getToken } from '../../api';
import { ApiProduct, emptySpec } from '../../api/product';
import ProductSpecs from './ProductSpecs';
import AddSpecModal from './AddSpecModal';
import SortSpecsModal from './SortSpecsModal';
import { ApiImage } from '../../api/image';
import ImageThumb from '../../components/ImageThumb';
import SortableList, { reorder } from '../../components/SortableList';
import ProductAttributes from './ProductAttributes';
import SortMode from './SortMode';
const config = require('../../config.json');

interface ProductEditProps extends RouteComponentProps<{ id?: string }> {
}

const ProductEditView: FC<ProductEditProps> = ({ match }) => {
    const fileInputRef: RefObject<HTMLInputElement> = React.createRef();
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ product, setProduct ] = useState<Partial<ApiProduct>>({});
    const [ productSpecs, setProductSpecs ] = useState<ApiProduct[]>([]);
    const [ specQuery, setSpecQuery ] = useState<string>('');
    const [ showColumnModal, setShowColumnModal ] = useState<boolean>(false);
    const [ showSortModal, setShowSortModal ] = useState<boolean>(false);
    const [ sortMode, setSortMode ] = useState<boolean>(false);
    const history = useHistory();

    useEffect(() => {
        const main = document.querySelector('main')
        if (main) {
            main.style.overflow = showSortModal ? 'hidden' : 'auto';
        }
    }, [showSortModal]);

    const fetch = useCallback(() => {
        if (!id) return;
            
        setIsLoading(true);
        api.getProduct(parseInt(id), 'attributes,category,specs,images').then(({ data }) => {
            const tmp = data;
            setProduct(data);
            api.listAllProducts({ parentId: data.id }, 'specs').then(({ data }) => {
                if (data.length > 0) {
                    setProductSpecs(data);
                    setIsLoading(false);
                } else {
                    api.listAllProducts({ categoryId: tmp.category?.id }, 'specs').then(({ data }) => {
                        setProductSpecs(data.length > 0 ? data : [tmp]);
                        setIsLoading(false);
                    });
                }
            });
        });
    }, [id]);
    useEffect(() => fetch(), [id]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setProduct({
            ...product,
            [name]: value,
        });
    }

    const save = (e: FormEvent): void => {
        e.preventDefault();
        setIsLoading(true);

        api.putProduct(product).then(({ data }) => {
            setIsLoading(false);
            toast('Product succesvol opgeslagen', 'success');
            if (!id) {
                history.push(`/products/${data.id}/edit`);
            } else {
                fetch();
            }
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Whoops, er ging iets mis', 'error')
        });
    }

    const handleAutoComplete = (query: string, callback: (options: DropdownOption[]) => void) => {
        api.listCategories({ query, all: 1 }).then(({ data }) => {
            callback(data.map((o) => ({
                text: o.name,
                value: o.id,
            })));
        });
    }

    const getSpecs = (id: boolean = false): string[] => {
        if (productSpecs.length <= 0) {
            return [];
        }

        const labels: string[] = [];
        productSpecs[0].specifications?.map((spec) => {
            if (id) {
                labels.push(`${spec.specificationId}::${spec.specification.label || spec.specification.name}`);
            } else {
                labels.push(spec.specification.label || spec.specification.name);
            }
            return spec;
        });

        return labels;
    }

    const addSpec = (): void => {
        const newSpecs = [...productSpecs];
        const spec = cloneDeep(newSpecs[newSpecs.length - 1]);
        spec.id = `new-${newSpecs.length}`;
        spec.sku = '';
        spec.parentId = parseInt(product.id as string);
        spec.exactId = undefined;

        if (spec.specifications) {
            spec.specifications = spec.specifications.map((s) => {
                const ns = cloneDeep(emptySpec);
                ns.specificationId = s.specificationId;
                return ns;
            });
        }
        newSpecs.push(spec);
        setSpecQuery('');
        setProductSpecs(newSpecs);
    }

    const deleteSpec = (productId: number | string): void => {
        if (`${productId}`.indexOf('new') === -1) {
            api.deleteProduct(productId);
        }

        setProductSpecs(productSpecs.filter((o) => o.id !== productId));
        toast('Artikel succesvol verwijderd', 'success');
    }

    const deleteSpecColumn = (index: number): void => {
        const spec = getSpecs(true)[index];
        if (!spec) return;

        api.deleteProductSpecification(product.id as number, parseInt(spec.split('::')[0])).then(() => {
            toast('Specificatie succesvol verwijderd', 'success');
            fetch();
        });
    }

    const filterSpecs = (item: ApiProduct): boolean => {
        if (specQuery === '') return true;
        const reg = new RegExp(specQuery, 'i');
        return reg.test(item.sku);
    }

    const handleSpecUpdate = (index: number, spec: ApiProduct) => {
        const newSpecs = [...productSpecs];
        newSpecs[index] = spec;
        setProductSpecs(newSpecs);
    }

    const handleImageDelete = (image: ApiImage): void => {
        if (!product.id) {
            return;
        }

        api.unlinkProductImage(product.id as number, image.id).then(() => {
            fetch();
            toast('Afbeelding verwijderd');
        });
    }

    const uploadFile = (e: any): void => {
        api.uploadImage(e.target.files[0], undefined, product.id as number).then(() => {
            fetch();
            toast('Afbeelding toegevoegd');
        });
    }

    const sortImages = (result: any) => {
        if (result.destination && product.images) {
            const newImages = reorder(product.images, result.source.index, result.destination.index);
            setProduct({
                ...product,
                images: newImages,
            });
            api.sortImages(product.id as number, newImages);
        }
    }

    const sortSpecs = (reordered: string[]) => {
        api.sortProductSpecs(product.id as number, reordered).then(() => {
            fetch();
            toast('Specs opgeslagen');
        });
    }

    return (<>
        <PageHeader
            breadcrumb={{
                '/products': 'Producten',
                [`/products/${id ? `${id}/edit` : 'create'}`]: id ? product.sku || 'Nieuw' : 'Nieuw',
            }}
            title={`Product ${id ? 'wijzigen' : 'toevoegen'}`}
        >
            {product.id && !sortMode && <>
                <Button
                    href={`${config.baseUrl}/products/${product.id}/export?_token=${getToken()}`}
                    icon={faFileExcel}
                    label="Exporteren"
                    target="_blank"
                />
                <Button
                    icon={faRetweet}
                    label="Sorteren"
                    onClick={() => setSortMode(true)}
                />
            </>}
        </PageHeader>

        {product.id && sortMode && (
            <SortMode
                onCancel={() => setSortMode(false)}
                onUpdate={() => {
                    setSortMode(false);
                    fetch();
                }}
                specs={productSpecs}
            />
        )}
        {!sortMode && (<>
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Grid.Row>
                <Grid.Column md={6}>
                    <Segment card isLoading={isLoading}>
                        <Form.Input
                            error={errors.sku}
                            label="SKU"
                            name="sku"
                            onChange={handleInput}
                            required
                            value={product.sku || ''}
                        />
                        {!isLoading && <Form.Dropdown
                            autoComplete={handleAutoComplete}
                            clearable
                            defaultOption={product.category?.name}
                            label="Categorie"
                            name="categoryId"
                            onChange={handleInput}
                            placeholder="Kies een categorie"
                            required
                            value={product.categoryId || ''}
                        />}
                        <Form.Textarea
                            label="Omschrijving"
                            name="description"
                            onChange={handleInput}
                            rows={6}
                            value={product.description || ''}
                        />
                        <Form.Dropdown
                            label="Op voorraad"
                            name="inStock"
                            onChange={handleInput}
                            required
                            options={[{
                                text: 'Groen',
                                value: 1,
                            }, {
                                text: 'Geel',
                                value: 2,
                            }, {
                                text: 'Oranje',
                                value: 3,
                            }]}
                            value={product.inStock || 1}
                        />
                        <Form.Group>
                            <Checkbox
                                checked={product.showInShop}
                                label="Tonen in webshop"
                                onChange={({ checked }: any) => handleInput({ name: 'showInShop', value: checked })}
                            />
                        </Form.Group>
                        <Button
                            label="Opslaan"
                            primary
                            type="submit"
                        />
                        <Button
                            href={`/products`}
                            label="Annuleren"
                            link
                        />
                    </Segment>
                </Grid.Column>
                <Grid.Column md={6}>
                    {product.id && <Segment card padding="compact">
                        <p><b>Geschikt voor</b></p>
                        <ProductAttributes
                            attributeId={1}
                            product={product}
                            onUpdate={(p) => {
                                setProduct(p);
                            }}
                        />

                        <p style={{ marginTop: '1rem' }}><b>Minder geschikt voor</b></p>
                        <ProductAttributes
                            attributeId={2}
                            product={product}
                            onUpdate={(p) => {
                                setProduct(p);
                            }}
                        />
                    </Segment>}

                    {product.id && <Segment card padding="dense">
                        <Button
                            icon={faUpload}
                            onClick={() => fileInputRef.current?.click()}
                        />
                        <input
                            multiple
                            onChange={uploadFile}
                            ref={fileInputRef}
                            type="file"
                            style={{ display: 'none' }}
                            accept="image/*"
                        />
                        {product.images && <div>
                            <SortableList
                                items={product.images}
                                onUpdate={(result) => sortImages(result)}
                                renderListItem={(image: any) => <div>
                                    <ImageThumb
                                        image={image} key={`img-${image.id}`}
                                        onDelete={handleImageDelete}
                                        onRotate={() => fetch()}
                                        confirm="Weet je zeker dat je deze afbeelding bij dit product wilt verwijderen? De afbeelding blijft wel beschikbaar"
                                    />
                                </div>}
                            />
                        </div>}
                    </Segment>}
                </Grid.Column>
            </Grid.Row>

            {product.id && <Segment card>
                <Table.Actions autoSearch onSearch={(q: string) => setSpecQuery(q)} />
                <Table fluid>
                    <thead>
                        <Table.Row>
                            <Table.HeaderCell collapsing>
                                SKU
                            </Table.HeaderCell>
                            <Table.HeaderCell collapsing>
                                M.A.
                            </Table.HeaderCell>
                            {getSpecs().map((label: string, index: number) => (
                                <Table.HeaderCell
                                    collapsing key={`sth-${index}`}
                                >
                                    <span style={{ fontSize: 12 }}>{label}</span>
                                    <Confirm
                                        content="Weet je zeker dat je deze specificatie in z'n geheel wilt verwijderen?."
                                        onConfirm={() => deleteSpecColumn(index)}
                                        trigger={<Button 
                                            icon={faTrashAlt}
                                            padding="dense"
                                            style={{ marginLeft: 5 }}
                                        />}
                                    />
                                </Table.HeaderCell>
                            ))}
                            <Table.HeaderCell collapsing />
                            <Table.HeaderCell collapsing>
                                <Button
                                    icon={faPlus}
                                    onClick={() => setShowColumnModal(true)}
                                    padding="dense"
                                />
                                <Button
                                    icon={faRetweet}
                                    onClick={() => setShowSortModal(true)}
                                    padding="dense"
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </thead>
                    <tbody>
                        {productSpecs.filter(filterSpecs).map((ps, index) => (
                            <ProductSpecs
                                key={`spec-${ps.id}`}
                                onDelete={deleteSpec}
                                onUpdate={(spec: ApiProduct) => handleSpecUpdate(index, spec)}
                                product={ps}
                                refresh={fetch}
                                columns={getSpecs(true)}
                            />
                        ))}
                    </tbody>
                </Table>
                <div style={{ marginTop: 50 }}>
                    <Button
                        label="Artikel toevoegen"
                        icon={faPlus}
                        onClick={addSpec}
                        primary
                    />
                </div>
            </Segment>}
        </Form>
        </>)}
        {product.id && <>
            <AddSpecModal
                open={showColumnModal}
                onClose={(isAdded) => {
                    setShowColumnModal(false);
                    if (isAdded) {
                        fetch();
                    }
                }}
                productId={product.id as number}
            />
            <SortSpecsModal
                open={showSortModal}
                onClose={(sorted?: string[]) => {
                    if (sorted) {
                        sortSpecs(sorted);
                    }
                    setShowSortModal(false);
                }}
                productId={product.id as number}
                columns={getSpecs(true)}
            />
        </>}
    </>);
}

export default ProductEditView;
