import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { NotificationCenter } from './ApuKit';
import api, { getToken } from './api';
import { AppState } from './store';
import Drawer, { useCollapse } from './modules/Drawer';
import TopBar from './modules/TopBar';
import styles from './styles.module.scss';
import LoginView from './views/Auth/LoginView';
import CategoryListView from './views/CategoryView';
import ExactView from './views/ExactView';
import ImportView from './views/ImportView';
import MediaView from './views/MediaView';
import ProductListView from './views/ProductView';
import ProductEditView from './views/ProductView/Edit';
import PromotionListView from './views/PromotionView';
import PromotionEditView from './views/PromotionView/Edit';
import Loader from './ApuKit/elements/Loader';
import PromotionCategoryListView from './views/PromotionCategoryView';
// import Socket from './Socket';

export default (): JSX.Element => {
    const isCollapsed = useCollapse();
    const [ isLoading, setIsLoading ] = useState(getToken() || false);
    const profile = useSelector((state: AppState) => state.auth.profile);
        
    useEffect(() => {
        api.getProfile().then(() => setIsLoading(false)).catch(() => {
            setIsLoading(false);
        });

        const theme = localStorage.getItem('theme') || 'into-the-light';
        document.getElementsByTagName('html')[0].classList.add(theme);
    }, []);

    return (
        <Router>
            {isLoading ? (
                <Loader />
            ) : profile ? (<>
                <TopBar />
                <Drawer />
                <main className={[styles.main, isCollapsed && styles.isCollapsed].join(' ')}>
                    <div>
                        <Switch>
                            <Route component={CategoryListView} exact path="/categories/:parentId?" />
                            <Route component={ExactView} exact path="/exact" />
                            <Route component={ImportView} exact path="/import" />
                            <Route component={MediaView} exact path="/media/images" />
                            <Route component={ProductListView} exact path="/products" />
                            <Route component={ProductEditView} exact path="/products/create" />
                            <Route component={ProductEditView} exact path="/products/:id/edit" />
                            <Route component={PromotionCategoryListView} exact path="/promotion-categories/:parentId?" />
                            <Route component={PromotionListView} exact path="/promotions" />
                            <Route component={PromotionEditView} exact path="/promotions/create" />
                            <Route component={PromotionEditView} exact path="/promotions/:id/edit" />
                        </Switch>
                    </div>
                </main>
                {/* <Socket /> */}
            </>) : (
                <Switch>
                    <Route component={LoginView} path="*" />
                </Switch>
            )}
            <NotificationCenter />
        </Router>
    );
}
