import { faPlus, faSadCry } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import api from '../../api';
import { ApiCategory } from '../../api/category';
import { Button, PageHeader, Segment } from '../../ApuKit';
import Loader from '../../ApuKit/elements/Loader';
import SortableList, { reorder } from '../../components/SortableList';
import CreateCategoryModal from './CreateCategoryModal';
import ProductListView from '../ProductView';
import SortableItem from './SortableItem';
import styles from './styles.module.scss';

interface CategoryListViewProps extends RouteComponentProps<{ parentId?: string }> {
}

const CategoryListView: FC<CategoryListViewProps> = ({ match }): JSX.Element => {
    const [ showCreateModal, setShowCreateModal ] = useState<boolean>(false);
    const [ breadcrumb, setBreadcrumb ] = useState<{ [key: string]: string }>({'/categories': 'Categorieën'});
    const [ category, setCategory ] = useState<ApiCategory>();
    const [ categories, setCategories ] = useState<ApiCategory[]>([]);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const fetch = useCallback((): void => {
        const { params } = match;
        setIsLoading(true);

        if (params.parentId) {
            api.getCategory(parseInt(params.parentId), 'parent').then(({ data }) => setCategory(data));
        } else {
            setCategory(undefined);
        }

        api.listCategories({
            parentId: params.parentId ? parseInt(params.parentId) : undefined
        }, 'parent').then(({ data }) => {
            setCategories(data);
            setIsLoading(false);
        });
    }, [match]);
    useEffect(() => fetch(), [fetch, match]);

    const updateBreadcrumb = useCallback((): void => {
        const newBreadcrumb: any = {'/categories': 'Categorieën'};
        if (category) {
            if (category.parent) {
                newBreadcrumb[`/categories/${category.parentId}`] = category.parent.name;
            }
            newBreadcrumb[`/categories/${category.id}`] = category.name;
        }
        setBreadcrumb(newBreadcrumb);
    }, [category]);
    useEffect(() => updateBreadcrumb(), [updateBreadcrumb, category]);

    const onDragEnd = (result: any): void => {
        if (result.destination) {
            const newCategories = reorder(categories, result.source.index, result.destination.index);
            setCategories(newCategories);
            api.sortCategories(newCategories);
        } else if (result.combine) {
            const cat = find(categories, { id: parseInt(result.draggableId) });
            if (cat) {
                cat.parentId = parseInt(result.combine.draggableId);
                api.putCategory(cat).then(fetch);
            }
        }
    }

    return (<>
        <PageHeader
            breadcrumb={breadcrumb}
            title="Categorieën"
        >
            <Button
                icon={faPlus}
                onClick={() => setShowCreateModal(true)}
                padding="compact"
                primary
            />
        </PageHeader>

        {categories.length <= 0 && !isLoading ? (
            <Segment className={styles.noResults}>
                <FontAwesomeIcon icon={faSadCry} />
                <h5>Geen categorieën gevonden</h5>
                <Button
                    label="Categorie toevoegen"
                    onClick={() => setShowCreateModal(true)}
                    primary
                />
            </Segment>
        ) : (
            <SortableList
                items={categories}
                onUpdate={onDragEnd}
                renderListItem={(category) => <SortableItem
                    category={category}
                    fetch={fetch}
                />}
            />
        )}
        {!isLoading && <CreateCategoryModal
            onClose={(update: boolean) => {
                setShowCreateModal(false);
                if (update) fetch();
            }}
            open={showCreateModal}
            parent={category} 
        />}

        {category &&  <ProductListView categoryId={category.id} />}
        {isLoading && <Loader />}
    </>);
}

export default CategoryListView;
