import { faArrows, faChevronRight , faEuroSign, faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../api';
import { ApiCategory } from '../../api/category';
import { Button, Confirm, Segment, toast } from '../../ApuKit';
import { number } from '../../lib/util';
import CategoryEditModal from './EditCategoryModal';
import styles from './styles.module.scss';

interface SortableItemProps {
    category: ApiCategory,
    fetch: () => void,
}

const SortableItem: FC<SortableItemProps> = ({ category, fetch }): JSX.Element => {
    const [ showModal, setShowModal ] = useState<boolean>(false);

    const deleteCategory = (categoryId: number) => {
        api.deleteCategory(categoryId).then(() => {
            fetch();
            toast('Categorie succesvol verwijderd');
        });
    }

    const updatePrices = (categoryId: number) => {
        api.updateCategoryPrices(categoryId).then(() => {
            toast('Prijzen worden bijgewerkt op de achtergrond');
        });
    }

    return (
        <Segment
            className={styles.sortableItem}
            padding="compact"
        >
            <div>
                <FontAwesomeIcon icon={faArrows} />
                <span>
                    {category.name}
                </span>
            </div>
            {category.productsCount > 0 && (
                <Link
                    to={`/products?cat=${category.id}`}
                    style={{ marginRight: 25 }}
                >
                    {number(category.productsCount)}{' '}
                    producten
                </Link>
            )}
            {category.isCombined && (<span style={{ marginLeft: 15, marginRight: 15 }}>Producten gecombineerd</span>)}
            <Button
                icon={faPencil}
                onClick={() => setShowModal(true)}
            />
            <Button
                icon={faEuroSign}
                onClick={() => updatePrices(category.id)}
            />
            <Confirm
                content="Weet je zeker dat je deze categorie in z'n geheel wilt verwijderen? Alle subcategorieën zullen ook worden verwijderd en gekoppelde producten worden ontkoppeld."
                onConfirm={() => deleteCategory(category.id)}
                trigger={<Button icon={faTrashAlt} />}
            />
            <Button
                href={`/categories/${category.id}`}
                icon={faChevronRight}
                style={{ marginLeft: 15 }}
                trigger
            />
            <CategoryEditModal
                open={showModal}
                onClose={(save) => {
                    setShowModal(false);
                    if (save) {
                        fetch();
                    }
                }}
                category={category}
            />
        </Segment>
    );
}

export default SortableItem;
