import { faPlus, faUpload } from '@fortawesome/pro-light-svg-icons';
import React, { FC, FormEvent, RefObject, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Button, Form, PageHeader, Segment, Table, toast, Grid, DropdownOption } from '../../ApuKit';
import api from '../../api';
import { ApiPromotion } from '../../api/promotion';
import ImageThumb from '../../components/ImageThumb';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { ApiImage } from '../../api/image';
import SortableList, { reorder } from '../../components/SortableList';

interface PromotionEditProps extends RouteComponentProps<{ id?: string }> {
}

const PromotionEditView: FC<PromotionEditProps> = ({ match }) => {
    const fileInputRef: RefObject<HTMLInputElement> = React.createRef();
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ promotion, setPromotion ] = useState<Partial<ApiPromotion>>({});
    const history = useHistory();

    const fetch = useCallback(() => {
        if (!id) return;
            
        setIsLoading(true);
        api.getPromotion(parseInt(id)).then(({ data }) => {
            setPromotion(data);
            setIsLoading(false);
        });
    }, [id]);
    useEffect(() => fetch(), [id]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setPromotion({
            ...promotion,
            [name]: value,
        });
    }

    const handleLine = (index: number, { name, value }: { [key: string]: any }): void => {
        const newLines: any = [...(promotion.lines || [])];
        newLines[index][name] = value;
        setPromotion({
            ...promotion,
            lines: newLines,
        })
    }

    const addLine = () => {
        const newLines: any = [...(promotion.lines || [])];
        newLines.push({ sku: '', description: '' });
        setPromotion({
            ...promotion,
            lines: newLines,
        });
    }

    const removeLine = (index: number) => {
        const newLines: any = [...(promotion.lines || [])];
        setPromotion({
            ...promotion,
            lines: newLines.filter((o: any, i: number) => i !== index),
        });
    }

    const save = (e: FormEvent): void => {
        e.preventDefault();
        setIsLoading(true);

        api.putPromotion(promotion).then(({ data }) => {
            setIsLoading(false);
            toast('Promotie succesvol opgeslagen', 'success');
            history.push(`/promotions`);
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Whoops, er ging iets mis', 'error')
        });
    }

    const uploadFile = (e: any): void => {
        if (!promotion || !promotion.id) return;
        const pid = promotion.id as number;
        api.uploadImage(e.target.files[0], undefined, pid, 'promotion_id').then(({ data }) => {
            fetch();
        });
    }

    const handleCatAutoComplete = (query: string, callback: (options: DropdownOption[]) => void) => {
        api.listPromotionCategories({ query, all: 1 }).then(({ data }) => {
            callback(data.map((o) => ({
                text: o.name,
                value: o.id,
            })));
        });
    }

    const handleAutoComplete = (query: string, callback: (options: DropdownOption[]) => void) => {
        api.listAllProducts({ query, type: 'promos' }).then(({ data }) => {
            callback(data.map((o) => ({
                text: o.sku,
                value: o.id,
            })));
        });
    }

    const handleImageDelete = (image: ApiImage): void => {
        if (!promotion.id) {
            return;
        }

        api.unlinkPromotionImage(promotion.id as number, image.id).then(() => {
            fetch();
            toast('Afbeelding verwijderd');
        });
    }

    const sortImages = (result: any) => {
        if (result.destination && promotion.images) {
            const newImages = reorder(promotion.images, result.source.index, result.destination.index);
            setPromotion({
                ...promotion,
                images: newImages,
            });
            api.sortImages(promotion.id as number, newImages, 'promotions');
        }
    }

    return (<>
        <PageHeader
            breadcrumb={{
                '/promotions': 'Promotie',
                [`/promotions/${id ? `${id}/edit` : 'create'}`]: id ? promotion.name || 'Nieuw' : 'Nieuw',
            }}
            title={`Promotie ${id ? 'wijzigen' : 'toevoegen'}`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Grid.Row>
                <Grid.Column md={6}>
                    <Segment card isLoading={isLoading}>
                        <Form.Input
                            error={errors.name}
                            label="Naam"
                            name="name"
                            onChange={handleInput}
                            required
                            value={promotion.name || ''}
                        />
                        {!isLoading && <Form.Dropdown
                            autoComplete={handleCatAutoComplete}
                            clearable
                            defaultOption={promotion.category?.name}
                            label="Categorie"
                            name="promotionCategoryId"
                            onChange={handleInput}
                            placeholder="Kies een categorie"
                            required
                            value={promotion.promotionCategoryId || ''}
                        />}
                        {/* <Form.Input
                            label="Groep"
                            name="group"
                            onChange={handleInput}
                            value={promotion.group || ''}
                        /> */}
                        <Form.Textarea
                            label="Omschrijving"
                            name="description"
                            onChange={handleInput}
                            rows={6}
                            value={promotion.description || ''}
                        />
                        <Form.Dropdown
                            label="Actief/Inactief"
                            name="isActive"
                            options={[{
                                text: 'Actief',
                                value: true,
                            }, {
                                text: 'Inactief',
                                value: false,
                            }]}
                            onChange={handleInput}
                            value={promotion.isActive}
                        />
                        <Button
                            label="Opslaan"
                            primary
                            type="submit"
                        />
                        <Button
                            href={`/promotions`}
                            label="Annuleren"
                            link
                        />
                    </Segment>
                </Grid.Column>
                <Grid.Column md={6}>
                    <Segment card padding="dense">
                        <Button
                            icon={faUpload}
                            onClick={() => fileInputRef.current?.click()}
                        />
                        <input
                            multiple
                            onChange={uploadFile}
                            ref={fileInputRef}
                            type="file"
                            style={{ display: 'none' }}
                            accept="image/*,application/pdf"
                        />
                        <div>
                            {promotion.hasPdf && (
                                <ImageThumb
                                    image={{
                                        id: 0,
                                        extension: 'pdf',
                                        height: 0,
                                        name: 'PDF',
                                        orientation: 'portrait',
                                        src: '/empty-pdf.png',
                                        width: 0,
                                    }} 
                                    onDelete={() => {}}
                                />
                            )}
                            {promotion.images && (
                                <SortableList
                                    items={promotion.images}
                                    onUpdate={(result) => sortImages(result)}
                                    renderListItem={(image: any) => <div>
                                        <ImageThumb
                                            image={image}
                                            key={`img-${image.id}`}
                                            onDelete={handleImageDelete}
                                            onRotate={() => fetch()}
                                            confirm="Weet je zeker dat je deze afbeelding bij deze promotie wilt verwijderen? De afbeelding blijft wel beschikbaar"
                                        />
                                    </div>}
                                />
                            )}
                            {/* {promotion.images && promotion.images.map((image) => (
                                <ImageThumb
                                    image={image} 
                                    key={`img-${image.id}`}
                                    onDelete={handleImageDelete}
                                    onRotate={() => fetch()}
                                />
                            ))} */}
                        </div>
                    </Segment>
                </Grid.Column>
            </Grid.Row>

            <Segment card>
                <Table fluid>
                    <thead>
                        <Table.Row>
                            <Table.HeaderCell>
                                SKU/Naam
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Omschrijving
                            </Table.HeaderCell>
                            <Table.HeaderCell collapsing />
                        </Table.Row>
                    </thead>
                    <tbody>
                        {promotion && promotion.lines && promotion.lines.map((line, index) => (
                            <Table.Row>
                                <Table.Cell style={{ verticalAlign: 'top' }}>
                                    <Form.Dropdown
                                        defaultOption={line.sku}
                                        autoComplete={handleAutoComplete}
                                        name="productId"
                                        onChange={(data: any) => handleLine(index, data)}
                                        placeholder="Kies een product"
                                        value={line.productId}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <Form.Textarea
                                        name="description"
                                        rows={4}
                                        onChange={(data: any) => handleLine(index, data)}
                                        value={line.description}
                                    />
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    <Button
                                        trigger
                                        icon={faTrashAlt}
                                        onClick={() => removeLine(index)}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </tbody>
                </Table>
                <div style={{ marginTop: 50 }}>
                    <Button
                        label="Artikel toevoegen"
                        icon={faPlus}
                        primary
                        onClick={() => addLine()}
                    />
                </div>
            </Segment>
        </Form>
    </>);
}

export default PromotionEditView;
