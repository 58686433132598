import { faUpload } from '@fortawesome/pro-light-svg-icons';
import React, { RefObject, useState } from 'react';
import api, { ApiMeta } from '../../api';
import { ApiImage } from '../../api/image';
import { Button, PageHeader, Progress, Segment, Table, toast } from '../../ApuKit';
import ImageThumb from '../../components/ImageThumb';
import styles from './styles.module.scss';

export default () => {
    const fileInputRef: RefObject<HTMLInputElement> = React.createRef();
    const [ images, setImages ] = useState<ApiImage[]>([]);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ remQuery, setQuery ] = useState<string>('');
    const [ isUploading, setIsUploading ] = useState<boolean>(false);
    const [ uploadProgress, setUploadProgress ] = useState<number>(0);
    const [ totalUploadProgress, setTotalUploadProgress ] = useState<number>(0);

    const handleDelete = (image: ApiImage): void => {
        api.deleteImage(image.id).then(() => {
            toast('Afbeelding verwijderd');
            handleSearch();
        });
    }

    const handleSearch = (query?: string, page?: number): void => {
        setIsLoading(true);
        if (query) {
            setQuery(query);
        }

        api.listImages({ query: query || remQuery, page: page || 1 }).then(({ data }) => {
            setIsLoading(false);
            setImages(data.data);
            setMeta(data.meta);
        });
    }

    const handleFileUpload = (e: any): void => {
        setIsUploading(true);
        uploadFile(e.target.files, 0, e.target.files.length);
    }

    const uploadFile = (files: FileList, index: number, max: number): void => {
        setTotalUploadProgress(index / max);
        setUploadProgress(0);

        if (index >= max) {
            setIsUploading(false);
            handleSearch();
            return;
        }

        api.uploadImage(files[index], (progressEvent: any) => {
            setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }).then(() => {
            handleSearch();
            uploadFile(files, index + 1, max);
        });
    }

    return (<>
        <PageHeader
            breadcrumb={{'media/images': 'Media'}}
            title="Afbeeldingen"
        >
            <Button
                icon={faUpload}
                onClick={() => fileInputRef.current?.click()}
            />
            <input
                multiple
                onChange={handleFileUpload}
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
                accept="image/*"
            />
        </PageHeader>
        
        {isUploading && <Segment card>
            <Progress progress={uploadProgress} style={{ marginBottom: '.5rem' }} />
            <Progress progress={totalUploadProgress * 100} />
        </Segment>}

        <Segment card isLoading={isLoading}>
            <Table.Actions onSearch={handleSearch} />
        </Segment>

        <div className={styles.container}>
            {images.length > 0 && images.map((image, index) => (
                <div key={index}>
                    <ImageThumb
                        onRotate={() => handleSearch()}
                        onDelete={handleDelete}
                        image={image}
                    />
                </div>
            ))}
            <div style={{ clear: 'both' }} />
        </div>
        <div style={{ clear: 'both' }} />

        {meta && <Table.Pagination
            meta={meta}
            onChange={(page) => handleSearch(undefined, page)}
        />}
    </>);
}
