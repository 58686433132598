import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from '.';
import { ApiCategory } from './category';
import { ApiImage } from './image';

export interface ApiProductAttribute {
    id?: number | string,
    attribute?: {
        name: string,
        abbr: string,
        label: string,
    },
    attributeId: number,
    value: string,
}

export interface ApiProductSpecification {
    id?: number | string,
    numericValue: number
    specification: {
        name: string,
        abbr: string,
        label: string,
    },
    specificationId: number,
    stringValue: string,
    position: number,
    value: string | number,
}

export interface ApiProduct {
    id: number | string,
    categoryId: number,
    category?: ApiCategory,
    categories?: ApiCategory[],
    children?: ApiProduct[],
    childrenCount?: number,
    description?: string,
    exactChildrenCount?: number,
    exactId?: string,
    images?: ApiImage[],
    imagesCount?: number,
    parentId: number,
    parent?: ApiProduct,
    sku: string,
    showInShop: boolean,
    attributes?: ApiProductAttribute[],
    specifications?: ApiProductSpecification[],
    inStock?: number,
    minAmount?: number
}

export const emptySpec: ApiProductSpecification = {
    numericValue: 0,
    specification: {
        name: '',
        abbr: '',
        label: '',
    },
    specificationId: 0,
    position: 0,
    stringValue: '',
    value: '',
}

export default {
    addBulkExact: (selection: any[], categoryId: number): AxiosPromise => {
        return axios.post('products/bulk/add-exact', { selection, categoryId });
    },
    addSpecColumn: (productId: number, data: any): AxiosPromise => {
        return axios.post(`products/${productId}/add-spec-column`, data);
    },
    delete: (productId: number | string): AxiosPromise => {
        return axios.delete(`products/${productId}`);
    },
    get: (productId: number | string, qWith?: string): AxiosPromise<ApiProduct> => {
        return axios.get(`products/${productId}?${qs.stringify({
            with: qWith,
        })}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiProduct[]>> => {
        return axios.get(`products?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    listAll: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiProduct[]> => {
        return axios.get(`products?${qs.stringify({
            ...query,
            with: qWith,
            all: 1,
        })}`);
    },
    put: (category: Partial<ApiProduct>): AxiosPromise<ApiProduct> => {
        if (category.id) {
            return axios.put(`products/${category.id}`, category);
        } else {
            return axios.post('products', category);
        }
    },
    deleteSpecification: (productId: number, specId: number): AxiosPromise => {
        return axios.delete(`products/${productId}/delete-specifications/${specId}`);
    },
    saveSpecifications: (product: ApiProduct): AxiosPromise<ApiProduct> => {
        return axios.post(`products/save-specifications`, product);
    },
    sortSpecs: (productId: number, specs: string[]): AxiosPromise => {
        return axios.post(`products/${productId}/sort-specs`, { specs });
    },
    unlinkImage: (productId: number, imageId: number): AxiosPromise => {
        return axios.post(`products/${productId}/unlink-image`, { imageId });
    },
    updateBulkCategory: (selection: number[], categoryId: number): AxiosPromise => {
        return axios.post('products/bulk/update-category', {
            selection,
            categoryId
        });
    },
    deleteBulk: (selection: number[]): AxiosPromise => {
        return axios.post('products/bulk/delete', { selection });
    }
}
