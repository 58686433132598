import { faPencil, faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Confirm, Menu, PageHeader, Segment, Table, toast } from '../../ApuKit';
import api, { ApiMeta } from '../../api';
import { ApiPromotion } from '../../api/promotion';


const PromotionListView: FC = (): JSX.Element => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ promotions, setPromotions ] = useState<ApiPromotion[]>([]);

    const fetch = (query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listPromotions({ query, page }).then(({ data }) => {
            setMeta(data.meta);
            setPromotions(data.data);
            setIsLoading(false);
        });
    }
    useEffect(() => fetch(), []);

    const deletePromotion = (promoId: number | string): void => {
        api.deletePromotion(promoId).then(() => {
            fetch();
            toast('Promotie succesvol verwijderd');
        });
    }

    return (<>
        <PageHeader title="Promoties" breadcrumb={{'/promotions': 'Promoties'}}>
            <Button
                icon={faPlus}
                href="/promotions/create"
            />
        </PageHeader>

        <Segment card isLoading={isLoading}>
            <Table.Actions
                onSearch={fetch}
                remember
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            Naam
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing>
                            Categorie
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {promotions.length > 0 ? promotions.map((promotion: any, index) => (
                        <Table.Row key={`row-${promotion.id}`}>
                            <Table.Cell>
                                <Link to={`/promotions/${promotion.id}/edit`}>
                                    {promotion.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                {promotion.category ? promotion.category.name : '-'}
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Wijzigen"
                                        href={`/promotions/${promotion.id}/edit`}
                                    />
                                    <Menu.Divider />
                                    <Confirm
                                        content="Weet je zeker dat je deze promotie wilt verwijderen?"
                                        onConfirm={() => deletePromotion(promotion.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt}
                                            content="Verwijderen"
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}

export default PromotionListView;
