import { faPlus, faSadCry } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import api from '../../api';
import { ApiPromotionCategory } from '../../api/promocategory';
import { Button, PageHeader, Segment } from '../../ApuKit';
import Loader from '../../ApuKit/elements/Loader';
import SortableList, { reorder } from '../../components/SortableList';
import CreateCategoryModal from './CreateCategoryModal';
import ProductListView from '../ProductView';
import SortableItem from './SortableItem';
import styles from './styles.module.scss';

interface PromotionCategoryListViewProps extends RouteComponentProps<{ parentId?: string }> {
}

const PromotionCategoryListView: FC<PromotionCategoryListViewProps> = ({ match }): JSX.Element => {
    const [ showCreateModal, setShowCreateModal ] = useState<boolean>(false);
    const [ breadcrumb, setBreadcrumb ] = useState<{ [key: string]: string }>({'/promotion-categories': 'Categorieën'});
    const [ promotionCategory, setPromotionCategory ] = useState<ApiPromotionCategory>();
    const [ promotionCategories, setPromotionCategories ] = useState<ApiPromotionCategory[]>([]);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);

    const fetch = useCallback((): void => {
        const { params } = match;
        setIsLoading(true);

        if (params.parentId) {
            api.getPromotionCategory(parseInt(params.parentId), 'parent').then(({ data }) => setPromotionCategory(data));
        } else {
            setPromotionCategory(undefined);
        }

        api.listPromotionCategories({
            parentId: params.parentId ? parseInt(params.parentId) : undefined
        }, 'parent').then(({ data }) => {
            setPromotionCategories(data);
            setIsLoading(false);
        });
    }, [match]);
    useEffect(() => fetch(), [fetch, match]);

    const updateBreadcrumb = useCallback((): void => {
        const newBreadcrumb: any = {'/promotion-categories': 'Categorieën'};
        if (promotionCategory) {
            if (promotionCategory.parent) {
                newBreadcrumb[`/promotion-categories/${promotionCategory.parentId}`] = promotionCategory.parent.name;
            }
            newBreadcrumb[`/promotion-categories/${promotionCategory.id}`] = promotionCategory.name;
        }
        setBreadcrumb(newBreadcrumb);
    }, [promotionCategory]);
    useEffect(() => updateBreadcrumb(), [updateBreadcrumb, promotionCategory]);

    const onDragEnd = (result: any): void => {
        if (result.destination) {
            const newPromotionCategories = reorder(promotionCategories, result.source.index, result.destination.index);
            setPromotionCategories(newPromotionCategories);
            api.sortPromotionCategories(newPromotionCategories);
        } else if (result.combine) {
            const cat = find(promotionCategories, { id: parseInt(result.draggableId) });
            if (cat) {
                cat.parentId = parseInt(result.combine.draggableId);
                api.putPromotionCategory(cat).then(fetch);
            }
        }
    }

    return (<>
        <PageHeader
            breadcrumb={breadcrumb}
            title="Promotie Categorieën"
        >
            <Button
                icon={faPlus}
                onClick={() => setShowCreateModal(true)}
                padding="compact"
                primary
            />
        </PageHeader>

        {promotionCategories.length <= 0 && !isLoading ? (
            <Segment className={styles.noResults}>
                <FontAwesomeIcon icon={faSadCry} />
                <h5>Geen categorieën gevonden</h5>
                <Button
                    label="Categorie toevoegen"
                    onClick={() => setShowCreateModal(true)}
                    primary
                />
            </Segment>
        ) : (
            <SortableList
                items={promotionCategories}
                onUpdate={onDragEnd}
                renderListItem={(promotionCategory) => <SortableItem
                    category={promotionCategory}
                    fetch={fetch}
                />}
            />
        )}
        {!isLoading && <CreateCategoryModal
            onClose={(update: boolean) => {
                setShowCreateModal(false);
                if (update) fetch();
            }}
            open={showCreateModal}
            parent={promotionCategory} 
        />}

        {/* {promotionCategory &&  <ProductListView promotionCategoryId={promotionCategory.id} />} */}
        {isLoading && <Loader />}
    </>);
}

export default PromotionCategoryListView;
