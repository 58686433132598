import React, { FC, HTMLAttributes } from 'react';
import styles from './styles.module.scss';

interface ColumnProps extends HTMLAttributes<HTMLDivElement> {
    isHidden?: string[],
    xs?: number,
    sm?: number,
    md?: number,
    lg?: number,
}

const Column: FC<ColumnProps> = ({
    children,
    className,
    isHidden,
    xs,
    sm,
    md,
    lg,
    ...props
}): JSX.Element => {
    const classes = [];

    if (xs) {
        classes.push(styles[`xs-${xs}`]);
    }
    if (sm) {
        classes.push(styles[`sm-${sm}`]);
    }
    if (md) {
        classes.push(styles[`md-${md}`]);
    }
    if (lg) {
        classes.push(styles[`lg-${lg}`]);
    }

    if (isHidden) {
        if (isHidden.includes('xs')) {
            classes.push(styles.hiddenXs);
        }
        if (isHidden.includes('sm')) {
            classes.push(styles.hiddenSm);
        }
        if (isHidden.includes('md')) {
            classes.push(styles.hiddenMd);
        }
        if (isHidden.includes('lg')) {
            classes.push(styles.hiddenLg);
        }
    }

    return (
        <div
            className={[
                styles.column,
                ...classes,
                className || '',
            ].join(' ')}
            {...props}
        >
            {children}
        </div>
    );
}

export default Column;
