import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faImages, faPlug, faTags, faThLarge, faUpload } from '@fortawesome/pro-light-svg-icons';
import { faTachometerAltFastest } from '@fortawesome/pro-light-svg-icons';
import { Menu, Segment } from '../../ApuKit';
import { useWindowResize } from '../../ApuKit/lib/hooks';
import Logo from '../../components/Logo';
import styles from './styles.module.scss';
import { faBullhorn } from '@fortawesome/pro-solid-svg-icons';

export const collapse = (state?: boolean) => {
    dispatchEvent(new CustomEvent('toggle-drawer', { detail: { state }}));
}

export const useCollapse = () => {
    const [ isCollapsed, setIsCollapsed ] = useState(false);

    useEffect(() => {
        const handleCollapse = (e: any) => {
            if (e.detail && e.detail.state !== undefined) {
                setIsCollapsed(e.detail.state);
            } else {
                setIsCollapsed(!isCollapsed);
            }
        };
        window.addEventListener('toggle-drawer', handleCollapse);
        return () => window.removeEventListener('toggle-drawer', handleCollapse);
    });

    return isCollapsed;
}

export default () => {
    const isCollapsed = useCollapse();
    const location = useLocation();
    const windowCollapse = () => collapse(window.innerWidth <= 992);
    useWindowResize(windowCollapse, 100);
    useEffect(windowCollapse, []);

    return (
        <Segment
            className={[
                styles.wrapper,
                isCollapsed && styles.isCollapsed,
            ].join(' ')}
            padding="none"
        >
            <div className={styles.base}>
                <div className={styles.logo}>
                    <FontAwesomeIcon icon={faBars} onClick={() => collapse()} />
                    <Logo />
                </div>
                <Menu>
                    <Menu.Item
                        active={location.pathname === '/'}
                        className={styles.menuItem}
                        content="Dashboard"
                        href="/"
                        icon={faTachometerAltFastest}
                    />
                    <Menu.Item
                        active={location.pathname === '/exact'}
                        className={styles.menuItem}
                        content="Exact Online"
                        href="/exact"
                        icon={faPlug}
                    />
                </Menu>

                <Menu>
                    <Menu.Header>Producten</Menu.Header>
                    <Menu.Item
                        active={location.pathname === '/products'}
                        className={styles.menuItem}
                        content="Overzicht"
                        href="/products"
                        icon={faTags}
                    />
                    <Menu.Item
                        active={location.pathname === '/categories'}
                        className={styles.menuItem}
                        content="Categorieën"
                        href="/categories"
                        icon={faThLarge}
                    />
                    <Menu.Item
                        active={location.pathname === '/import'}
                        className={styles.menuItem}
                        content="Importeren"
                        href="/import"
                        icon={faUpload}
                    />
                </Menu>

                <Menu>
                    <Menu.Header>Promoties</Menu.Header>
                    <Menu.Item
                        active={location.pathname === '/promotions'}
                        className={styles.menuItem}
                        content="Overzicht"
                        href="/promotions"
                        icon={faBullhorn}
                    />
                    <Menu.Item
                        active={location.pathname === '/promotion-categories'}
                        className={styles.menuItem}
                        content="Categorieën"
                        href="/promotion-categories"
                        icon={faThLarge}
                    />
                </Menu>

                <Menu>
                    <Menu.Header>Media</Menu.Header>
                    <Menu.Item
                        active={location.pathname === '/media/images'}
                        className={styles.menuItem}
                        content="Afbeeldingen"
                        href="/media/images"
                        icon={faImages}
                    />
                </Menu>
            </div>
        </Segment>
    );
}
