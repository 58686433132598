import React, { useEffect, useState } from 'react';
import api from '../../api';
import { Form, Message, PageHeader, Segment, toast } from '../../ApuKit';

export default () => {
    const [ errors, setErrors ] = useState<any>({});
    const [ files, setFiles ] = useState<any[]>([]);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const handleFile = (count: number = 0) => {
        setErrors({});
        if (!files[count]) {
            setFiles([]);
            return;
        }
        setIsLoading(true);
        
        api.uploadImport(files[count]).then(() => {
            setIsLoading(false);
            handleFile(count + 1);
            toast('De import is succesvol geüpload en zal nu worden verwerkt');
        }).catch(({ response }) => {
            setIsLoading(false);
            if (response.data.errors.file) {
                toast('Ongeldig bestandsformaat, upload CSV of Excel', 'error');
            } else {
                setFiles([]);
                toast('Er zijn fouten gevonden in het bestand', 'error');
                setErrors(response.data.errors);
            }
        });
    }

    useEffect(() => {
        if (files.length) {
            handleFile();
        }
    }, [files]);

    return (<>
        <PageHeader
            breadcrumb={{'import': 'Importeren'}}
            title="Producten importeren"
        />
        <Segment card isLoading={isLoading}>
            <Form.Input
                accept=".csv, .xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                info="Toegestane bestandsformaten: .csv, .xls, .xlsx"
                label="Selecteer een bestand"
                multiple
                onChange={(e: any) => {
                    const arr: any[] = [];
                    Array.from(e.files).forEach((f: any) => arr.push(f));
                    setFiles(arr);
                }}
                type="file"
            />
        </Segment>

        {Object.keys(errors).map((line, index) => (
            <Message
                key={`err-${index}`}
                error
                title={`Fout op regel ${line}`}
            >
                <ul>
                    {errors[line].map((o: string, i: number) => <li key={`err-${index}-${i}`}>{o}</li>)}
                </ul>
            </Message>
        ))}
    </>);
}
