import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiImage } from './image';

export interface ApiPromotionCategory {
    id: number,
    childrenCount: number,
    name: string,
    description?: string,
    parent?: ApiPromotionCategory,
    parentId: number,
    position: number,
    productsCount: number,
    slug: string,
    isCombined: boolean,
    headerImage?: ApiImage,
    thumbnail?: ApiImage,
    filters?: any[],
    searchterms: string,
}

export default {
    delete: (categoryId: number): AxiosPromise => {
        return axios.delete(`promotion-categories/${categoryId}`);
    },
    get: (categoryId: number, qWith?: string): AxiosPromise<ApiPromotionCategory> => {
        return axios.get(`promotion-categories/${categoryId}?${qs.stringify({
            with: qWith,
        })}`);
    },
    list: (query?: { [key: string]: any }, qWith?: string): AxiosPromise<ApiPromotionCategory[]> => {
        return axios.get(`promotion-categories?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (category: Partial<ApiPromotionCategory>): AxiosPromise<ApiPromotionCategory> => {
        if (category.id) {
            return axios.put(`promotion-categories/${category.id}`, category);
        } else {
            return axios.post('promotion-categories', category);
        }
    },
    sort: (categories: ApiPromotionCategory[]): AxiosPromise => {
        return axios.post('promotion-categories/sort', { categories });
    },
    updatePrices: (categoryId: number): AxiosPromise => {
        return axios.post(`promotion-categories/${categoryId}/update-prices`);
    },
}
