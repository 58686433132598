import { faTrashAlt, faPlug as faLightPlug } from '@fortawesome/pro-light-svg-icons';
import { faPlug } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find, findIndex } from 'lodash';
import React, { FC, useState } from 'react';
import api from '../../api';
import { ApiProduct } from '../../api/product';
import { Confirm, Input, Menu, Table, toast } from '../../ApuKit';
const config = require('../../config.json');

interface ProductSpecsProps {
    onDelete: (specId: number | string) => void,
    onUpdate: (spec: ApiProduct) => void,
    product: ApiProduct,
    refresh: () => void,
    columns: string[],
}

const ProductSpecs: FC<ProductSpecsProps> = ({ onDelete, onUpdate, product, refresh, columns }): JSX.Element => {
    const [ error, setError ] = useState<boolean>(false);
    const [ doSave, setDoSave ] = useState<boolean>(false);
    
    const handleSku = (value: string): void => {
        onUpdate({
            ...product,
            sku: value,
        });
        setDoSave(true);
    }

    const handleMinAmount = (value: number): void => {
        onUpdate({
            ...product,
            minAmount: value,
        });
        setDoSave(true);
    }

    const handleInput = (index: number, value: string): void => {
        if (!product.specifications) return;
        product.specifications[index].value = value;
        onUpdate(product);
        setDoSave(true);
    }

    const save = (): void => {
        setError(false);
        if (doSave) {
            api.saveSpecifications(product).then(({ data }) => {
                onUpdate({
                    ...product,
                    id: data.id,
                });
            }).catch(() => setError(true));
            setDoSave(false);
        }
    }

    const updateFromExact = () => {
        api.updateFromExact(parseInt(product.id as string)).then(({ data }) => {
            if (data.status) {
                toast('Artikel bijgewerkt', 'error');
            } else {
                toast('Artikel niet gevonden in Exact Online', 'error');
            }
            refresh();
        }).catch(() => {
            window.location.href = `${config.baseUrl}/connect/exact`;
        });
        
    }

    return (
        <Table.Row>
            <Table.Cell style={{ minWidth: 120 }}>
                <Input
                    error={error}
                    onBlur={() => save()}
                    onChange={({ value }: any) => handleSku(value)}
                    value={product.sku}
                />
            </Table.Cell>
            <Table.Cell style={{ minWidth: 70 }}>
                <Input
                    onBlur={() => save()}
                    onChange={({ value }: any) => handleMinAmount(value)}
                    value={product.minAmount || 1}
                    style={{ width: 70 }}
                />
            </Table.Cell>
            {columns.map((col) => {
                const id = parseInt(col.split('::')[0]);
                const index = findIndex(product.specifications, { specificationId: id });
                const spec = find(product.specifications, { specificationId: id });
                return <Table.Cell key={`cell-${index}`}>
                    {spec ? <Input
                        onBlur={() => save()}
                        onChange={({ value }: any) => handleInput(index, value)}
                        value={spec.value}
                    /> : '-'}
                </Table.Cell>


                // product.specifications?.map((spec, index) => (
                //     <Table.Cell key={`ps-${product.id}-${index}`}>
                //         <Input
                //             onBlur={() => save()}
                //             onChange={({ value }: any) => handleInput(index, value)}
                //             value={spec.value}
                //         />
                //     </Table.Cell>
                // ))}
            })}
            <Table.Cell
                collapsing
                icon={product.exactId ? (
                    <FontAwesomeIcon icon={faPlug} className="success" />
                 ) : (<FontAwesomeIcon
                    icon={faLightPlug}
                    onClick={() => updateFromExact()}
                    style={{ cursor: 'pointer' }}
                />)}
            />
            <Table.Cell collapsing>
                <Menu dropdown>
                    <Menu.Item
                        content="Ophalen uit Exact"
                        icon={faLightPlug}
                        onClick={() => updateFromExact()}
                    />
                    <Menu.Item
                        content="Doorzetten naar Exact"
                        icon={faLightPlug}
                    />
                    <Menu.Divider />
                    <Confirm
                        content="Weet je zeker dat je dit artikel wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden"
                        onConfirm={() => onDelete(product.id)}
                        trigger={<Menu.Item
                            icon={faTrashAlt}
                            content="Verwijderen"
                        />}
                    />
                </Menu>
            </Table.Cell>
        </Table.Row>
    );
}

export default ProductSpecs;
