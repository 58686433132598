import axios from 'axios';

export default {
    upload: (file: File): Promise<any> => {
        const data = new FormData();
        data.append('file', file);
 
        return axios.post('import/upload', data);
    },
}
