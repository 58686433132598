import axios, { AxiosPromise } from 'axios';
import qs from 'qs';

export interface ApiFilter {
    id: number,
    attribute_id: number,
    attribute: {
        name: string,
        slug: string,
    },
    options: string[],
}

export default {
    get: (filterId: number): AxiosPromise<ApiFilter> => {
        return axios.get(`filters/${filterId}`);
    },
    list: (query?: { [key: string]: any }): AxiosPromise<ApiFilter[]> => {
        return axios.get(`filters?${qs.stringify(query)}`);
    },
}
