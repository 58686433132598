import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from '.';
import { ApiImage } from './image';
import { ApiPromotionCategory } from './promocategory';

export interface ApiPromotion {
    id: number | string,
    name?: string,
    group?: string,
    category?: ApiPromotionCategory,
    promotionCategoryId?: number,
    description?: string,
    images?: ApiImage[],
    isActive: boolean,
    hasPdf: boolean | string,
    lines: {
        sku: string,
        productId: number,
        description: string,
    }[]
}

export default {
    delete: (promotionId: number | string): AxiosPromise => {
        return axios.delete(`promotions/${promotionId}`);
    },
    get: (promotionId: number | string, qWith?: string): AxiosPromise<ApiPromotion> => {
        return axios.get(`promotions/${promotionId}?${qs.stringify({
            with: qWith,
        })}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiPromotion[]>> => {
        return axios.get(`promotions?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (promotion: Partial<ApiPromotion>): AxiosPromise<ApiPromotion> => {
        if (promotion.id) {
            return axios.put(`promotions/${promotion.id}`, promotion);
        } else {
            return axios.post('promotions', promotion);
        }
    },
    unlinkImage: (promotionId: number, imageId: number): AxiosPromise => {
        return axios.post(`promotions/${promotionId}/unlink-image`, { imageId });
    },
}
