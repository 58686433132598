import React, { FC, useEffect, useState } from 'react';
import { Segment, Modal, Button } from '../../ApuKit';
import SortableList, { reorder } from '../../components/SortableList';

interface SortSpecsModalProps {
    onClose: (reordered?: string[]) => void,
    open: boolean,
    productId: number,
    columns: string[],
}

const SortSpecsModal: FC<SortSpecsModalProps> = ({ columns, onClose, open }): JSX.Element => {
    const [ sorted, setSorted ] = useState<string[]>(columns);
    useEffect(() => setSorted(columns), [columns]);

    const handleClose = (): void => {
        onClose();
    }

    const onDragEnd = (result: any): void => {
        if (result.destination) {
            setSorted(reorder(sorted, result.source.index, result.destination.index));
        }
    }

    const save = (): void => {
        onClose(sorted.map(o => o.split('::')[0]));
    }

    return (
        <Modal
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        label="Annuleren"
                        link
                        onClick={() => handleClose()}
                    />
                    <Button
                        label="Opslaan"
                        primary
                        onClick={() => save()}
                    />
                </div>
            )}
            header="Specs sorteren"
            onClose={() => handleClose()}
            open={open}
            size="medium"
        >
            <SortableList
                items={sorted.map((s, i) => {
                    const data = s.split('::');
                    return { id: data[0], label: data[1] };
                })}
                onUpdate={onDragEnd}
                renderListItem={(column) => (
                    <Segment padding="dense">
                        {column.label}
                    </Segment>
                )}
            />
        </Modal>
    );
}

export default SortSpecsModal;
