import React, { FC, useState } from 'react';
import api from '../../api';
import { ApiCategory } from '../../api/category';
import { Button, Checkbox, DropdownOption, Form, Modal, toast } from '../../ApuKit';

interface CategoryCreateModalProps {
    onClose: (update: boolean) => void,
    open: boolean,
    parent?: ApiCategory,
}

const CategoryCreateModal: FC<CategoryCreateModalProps> = ({ onClose, open, parent }) => {
    const [ error, setError ] = useState<boolean>(false);
    const [ selectedParent, setSelectedParent ] = useState<Partial<ApiCategory> | undefined>(parent);
    const [ name, setName ] = useState<string>('');
    const [ isCombined, setIsCombined ] = useState<boolean>(parent ? parent.isCombined : false);

    const save = (): void => {
        setError(false);
        api.putCategory({
            name,
            parentId: selectedParent?.id,
            isCombined,
        }).then(() => {
            toast('Categorie succesvol toegevoegd');
            setName('');
            setIsCombined(false);
            setSelectedParent(parent);
            onClose(true);
        }).catch(() => setError(true));
    }

    const handleAutoComplete = (query: string, callback: (options: DropdownOption[]) => void) => {
        api.listCategories({ query, all: 1 }).then(({ data }) => {
            callback(data.map((o) => ({
                text: o.name,
                value: o.id,
            })));
        });
    }

    return (
        <Modal
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        label="Annuleren"
                        link
                        onClick={() => { setName(''); onClose(false); }}
                    />
                    <Button
                        label="Ok"
                        primary
                        onClick={() => save()}
                    />
                </div>
            )}
            header={`Nieuwe categorie toevoegen${parent ? ` onder ${parent.name}` : ''}`}
            onClose={() => { setName(''); onClose(false); }}
            open={open}
            size="medium"
        >
            {parent && <Form.Dropdown
                autoComplete={handleAutoComplete}
                clearable
                defaultOption={parent?.name}
                label="Hoofdcategorie"
                name="parentId"
                onChange={({ value, option }) => setSelectedParent({ id: value, name: option.text })}
                placeholder="Kies een hoofdcategorie"
                required
                value={selectedParent?.id || ''}
            />}
            <Form.Input
                error={error}
                label="Naam"
                required
                onChange={({ value }: any) => setName(value)}
                value={name}
            />
            <Checkbox
                label="Gecombineerde producten"
                checked={isCombined}
                onChange={({ checked }: any) => setIsCombined(checked)}
            />
        </Modal>
    );
}

export default CategoryCreateModal;
